import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'

import IndexLayout from '../../layouts'
import Menu from '../../containers/Menu'
import Footer from '../../containers/Footer'
import blogData from '../../assets/blog'
import { BlogPost } from '../../components/Blog'


export default ({ pathContext: { intl: { originalPath } } }) => {
  const { locale } = useIntl()
  const slug = originalPath.split('/').filter((record: string) => record !== '').pop()
  const blogPostData = blogData[slug][locale]

  return (
    <IndexLayout>
      <Menu originalPath={originalPath} />
      <BlogPost {...blogPostData} />
      <Footer originalPath={originalPath} />
    </IndexLayout>
  )
}
