import autorImg from '../../images/team/torsten_petersen.png'
import { BlogPostType } from '../types'
import featuredImage from './image.png'


const common = {
  slug: 'welcome-to-our-blog',
  date: '15.04.2021',
  featuredImage,
  author: {
    name: 'DR. TORSTEN PETERSEN',
    photo: autorImg,
  },
  ownArticle: true,
  next: 'stefan-marquard-interview',
}

const de = {
  readtime: '2 min lesen',
  title: "WILLKOMMEN IN UNSEREM NEUEN BLOG",
  overview: "Willkommen in unserem neuen Blog, in dem praxisnah über neueste Entwicklungen rund um den Einsatz von künstlicher Intelligenz( KI)  in der Gastronomie und der Gemeinschaftsverpflegung sowie im Bereich Catering und Delivery berichtet und diskutiert wird.",
  postBody: `
    <img src="${featuredImage}" alt="robot image" />
    <p>Bis dato werden die heutigen modernen digitalen Möglichkeiten in dieser Branche noch kaum eingesetzt, obwohl sie ein gewaltiges Potenzial haben, eine deutliche Margen- und Ergebnisverbesserung für die gastronomischen Unternehmung zu erzielen.</p>
    <p>Mittel dazu sind eine datenbasierte Menü- und Personalplanung, Warenwirtschaft mit e-Procurement, Benchmarking und präzise Einkaufsempfehlungen, - und dies bis auf die Produktebene.</p>
    <p>Datenbasierte Einkaufs- und Umsatzprognosen erlauben es zudem Food Waste von Anfang zu vermeiden. Wir werden dieses Problem   an der Wurzel anpacken. Überlegungen, wie man übriggebliebene Lebensmittel effizient verwertet, werden weitgehend überflüssig. Vermeidung von Food Waste ist ein zentrales Thema in diesem Blog, wir gehen darauf ein, wie Food Waste durch den Einsatz von künstlicher Intelligenz bei der Einkaufsplanung erheblich reduziert oder gar ganz verhindert wird.</p>
    <p>Machen Sie mit, diskutieren Sie mit uns, schreiben Sie Kommentare, schicken Sie uns eigene Artikel. Wir würden uns freuen, wenn hier ein lebendiges und informatives Forum entsteht, welches die zunehmende Relevanz von KI in der Gastronomie widerspiegelt.</p>
    <p>
      <div>Dr. Torsten Petersen</div>
      <div>CEO Perspective Food AG</div>
    </p>
  `,
  authorOverview: "He studied German literature, sports, philosophy and economics at the Philips University of Marburg and Ruprecht Karls University Heidelberg, where he got his doctorate with honors. Banker at Commerzbank and Dresdner Bank, Member of the Board of Sparkasse Kiel. Managing director, founder and investor of various companies in the areas of financial business, life style, food.",
}

const en = {
  readtime: '11 min read',
  title: "WELCOME TO OUR NEW BLOG",
  overview: "We are proud to present and discuss the latest developments in the use of artificial intelligence (AI) in the restaurant and catering industry, as well as in the catering and delivery sector.",
  postBody: `
    <img src="${featuredImage}" alt="robot image" />
    <p>As of today, this industry still hardly makes use of state-of-the-art digital capabilities, despite the fact that AI offers a huge potential to create significant margin improvements for any gastronomic enterprise. Means to achieve this are data-based menu and staff planning, accurate stock management with e-procurement, benchmarking and precise purchasing recommendations, - and this down to the product level.</p>
    <p>Data-based purchasing and sales forecasts also make it possible to avoid food waste from the outset. We tackle the problem at its root. Thinking about how to efficiently utilize leftover food will become largely superfluous. Avoiding Food Waste is a central theme in this blog, we will go into how Food Waste can be significantly reduced or even prevented altogether by using AI in supply planning.</p>
    <p>Join us, discuss with us, write comments, send us your own articles. We would love to see emerge a lively and informative forum here, reflecting the increasing relevance of AI in the restaurant industry.</p>
    <p>
      <div>Dr. Torsten Petersen</div>
      <div>CEO Perspective Food AG</div>
    </p>
  `,
  authorOverview: "He studied German literature, sports, philosophy and economics at the Philips University of Marburg and Ruprecht Karls University Heidelberg, where he got his doctorate with honors. Banker at Commerzbank and Dresdner Bank, Member of the Board of Sparkasse Kiel. Managing director, founder and investor of various companies in the areas of financial business, life style, food.",
}

export default <BlogPostType>{
  de: {...common, ...de},
  en: {...common, ...en},
}
