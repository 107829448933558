import autorImg from '../../images/team/torsten_petersen.png'
import { BlogPostType } from '../types'
import featuredImage from './image.png'


const common = {
  slug: 'stefan-marquard-interview',
  date: '15.04.2021',
  featuredImage,
  author: {
    name: 'DR. TORSTEN PETERSEN',
    photo: autorImg,
  },
  ownArticle: false,
  prev: 'welcome-to-our-blog',
}

const de = {
  readtime: '11 min lesen',
  title: 'PLÄDOYER FÜR DEN (DIGITALEN) WANDEL',
  overview: 'Weder als Sternekoch, noch als TV-Star oder Unternehmer: Stefan Marquard, seines Zeichens auch Vater der JUNGEN WILDEN wollte sich nie anpassen. Das gilt auch für die Meinung zur eine der größten Zäsuren des vergangenen Jahrhunderts. In der Corona-Krise sieht Marquard alles andere als die Apokalypse. Warum er sich seinen ersten Laptop zugelegt hat, den Shutdown als Chance sieht und sein Restaurant ein für allemal zu schließen vielleicht gar nicht die schlechteste Option ist, verrät er im Interview mit ROLLING PIN.',
  postBody: `
    <h2>Stefan Marquard: "Die Corona-Krise ist eine einmalige Chance für die Gastronomie".</h2>
    <figure>
      <img src="${featuredImage}" alt="portrait of Stefan Marquard" />
      <figcaption>Krise? Chance! Stefan Marquard will die Corona-Zeit nun effektiv nutzen, um sich weiterzuentwickeln.</figcaption>
    </figure>
    <p>Also siehst du die Krise durchaus auch als Chance?</p>
    <p><strong>Marquard:</strong> Ich sehe die Krise eigentlich durchwegs als Chance. Dass irgendwann der große Knall kommt, war klar. In welcher Form konnte sich keiner vorstellen. Dass es ausgerechnet die gesamte Gastronomie und alles, was um sie gestrickt ist, lahmlegt, damit hätte niemand gerechnet. Aber ich habe von Anfang an gesagt: Wahrscheinlich ist Corona der größte Segen, der der Menschheit je widerfahren ist. Der Preis erscheint uns im Moment extrem hoch, unfair, schmerzhaft. Aber ich glaube, wenn die Verwandlung durch ist, werden wir früher oder später alle dankbar sein.</p>
    <p>Wahrscheinlich ist Corona der größte Segen, der der Menschheit je widerfahren ist.</p>
    <p>Warum das?</p>
    <p>Marquard: Ganz einfach. Man hat jetzt automatisch Zeit, alles, was man tut, oder getan hat, in Frage und auf den Prüfstand stellt. Wir können endlich reflektieren: Was habe ich gemacht? Warum habe ich es gemacht? War ich erfolgreich damit? Macht es Sinn? Werde ich meinen Mitarbeitern gerecht? Bin ich ein guter Chef? Es geht aber nicht nur um das Geschäftliche, sondern auch ums Private. Gehe ich gut mit mir selbst und meiner Familie um? Wir können jetzt  für die Zukunft wirklich alles neu ausrichten. Und die Zukunft wird in keinster Weise werden wie es einmal war.</p>
    <p>Wie können sich Unternehmen jetzt schon mit der Zukunft beschäftigen, wenn sie in der Gegenwart um ihr wirtschaftliches Überleben kämpfen?</p>
    <p><strong>Marquard:</strong> Ja, den Schock muss man erst verdauen. Dann muss man sich auch noch ein paar Tage über den Staat aufregen. Aber irgendwann ist das Thema durch. Dass mein Geld immer weniger wird, dass ich auf Eis gelegt bin, das ist alles klar. Aber wir haben zu essen, wir haben zu trinken. Uns geht das Geld aus, aber das betrifft uns alle. Und wir sollten jetzt auf keinen Fall Trübsal blasen. Wir können endlich in uns reinhören und neue Ideen zulassen.</p>
    <p>Der Corona-Masterplan lautet also: sich jetzt neu erfinden, jetzt an neue Konzepte denken und neue Ideen festhalten – und, wenn es wieder möglich ist, etwas davon umsetzen.</p>
    <p><strong>Marquard:</strong> Nicht nur. Man sollte jetzt wirklich einen Plan für die Zukunft entwickeln, und auch das Team miteinbeziehen. Ich muss dafür nicht warten, bis alles vorbei ist. Weil wie lange das Ganze dauert, weiß ich ja nicht. Deswegen ist es umso wichtiger, mir jetzt schon anzusehen, was ich tun kann. Vielleicht gibt es eine Möglichkeit, wie ich mit meinen Fähigkeiten Geld verdienen kann.</p>
    <p>Wie bringe ich meine Mitarbeiter dazu, über Zukunftsideen nachzudenken, wenn aktuell die Insolvenz im Raum steht und Arbeitsplätze gefährdet sind?</p>
    <p><strong>Marquard:</strong> Das ist nicht einfach. Ich bin mir sicher, dass mindestens ein Drittel aller Kleinbetriebe in der Gastronomie einen Cut machen und sich neu ausrichten muss. Viele Betriebe arbeiten schon seit vielen Jahren so, dass sie gerade noch über die Runden kommen. Und solchen Leuten empfehle ich, einfach mal aufzuschreiben, ob denen das Spaß macht und ob sie so weitermachen wollen. Da sage ich einfach: Lieber ein Ende mit Schrecken als ein Schrecken ohne Ende – ohne damit jemandem zu nahe treten zu wollen, wir sitzen alle im selben Boot. Aber das ist die Chance, dass wir uns neu aufstellen.</p>
    <p>Das ist das Thema. Ich wünsche mir, dass aus dieser Zeit auch ein Schwarmdenken entsteht. Gemeinsam sind wir stark. Es muss Leute geben, die Tacheles sprechen und die Massen bewegen können. Jene von uns, die in der Öffentlichkeit stehen, sollten sich connecten und gemeinsam eine Sprache sprechen.</p>
    <p>Ich bin fest davon überzeugt, dass sich in ein bis zwei Jahren alles auf der Welt komplett verändern wird. Denn so lange wird das Dauern, bis wir wieder einen Hauch von Normalität kriegen. Ich vermute, dass wir ein Auf und Ab haben, bis wir einen Impfstoff gefunden haben – oder bis alle sich angesteckt haben. Aber danach wird es einen anderen Zusammenhalt geben.</p>
    <p>Gibt es nicht auch ein gewisses Risiko, dass alles komplett schiefgehen könnte?</p>
    <p><strong>Marquard:</strong> Natürlich kann auch mal etwas schiefgehen. Aber auch das ist wichtig. Nur Scheitern macht einen Menschen stark. Wenn jemand erstmal gescheitert ist, dann geht er danach durch die Decke. Aber es ist niemand dazu verdammt, nichts zu tun – und schon gar nicht zu verhungern. Man muss einfach wollen, dann kann man auch was erreichen.</p>
    <p>Interview (in Auszügen) in «Rolling Pin» 02.04.2020</p>
  `,
  authorOverview: 'He studied German literature, sports, philosophy and economics at the Philips University of Marburg and Ruprecht Karls University Heidelberg, where he got his doctorate with honors. Banker at Commerzbank and Dresdner Bank, Member of the Board of Sparkasse Kiel. Managing director, founder and investor of various companies in the areas of financial business, life style, food.',
}

const en = {
  readtime: '11 min read',
  title: 'PLEA FOR THE (DIGITAL) CHANGE',
  overview: 'Neither as a star chef, nor as a TV star or entrepreneur: Stefan Marquard, who is also the father of the YOUNG WILD, has never wanted to conform. This also applies to his opinion on one of the greatest caesuras of the past century. Marquard sees the Corona crisis as anything but the apocalypse. In an interview with ROLLING PIN, he reveals why he bought his first laptop, sees the shutdown as an opportunity and why closing his restaurant once and for all might not be the worst option.',
  postBody: `
    <h2>Stefan Marquard: "The Corona crisis is a unique opportunity for the gastronomy".</h2>
    <figure>
      <img src="${featuredImage}" alt="Porträt von of Stefan Marquard" />
      <figcaption>Crisis? Opportunity! Stefan Marquard now wants to use the Corona time effectively to develop himself further.</figcaption>
    </figure>
    <p>So you also see the crisis as an opportunity?</p>
    <p><strong>Marquard:</strong> I actually see the crisis as an opportunity. It was clear that the big bang would come at some point. No one could have imagined what form it would take. No one could have imagined that it would paralyze the entire catering industry and everything that is knitted around it. But I said from the very beginning: Corona is probably the greatest blessing that has ever befallen mankind. The price seems extremely high, unfair, painful at the moment. But I believe that when the transformation is through, sooner or later we will all be grateful.</p>
    <p>Probably Corona is the greatest blessing that has ever happened to mankind.</p>
    <p>Why is that?</p>
    <p><strong>Marquard:</strong> Quite simply. One now automatically has time to question and put to the test everything one does, or has done. We can finally reflect: What have I done? Why did I do it? Was I successful with it? Does it make sense? Am I doing justice to my employees? Am I a good boss? But it is not only about business, but also about private life. Am I treating myself and my family well? We can really realign everything now for the future. And the future will be nothing like it once was.</p>
    <p>How can companies deal with the future now, when they are fighting for their economic survival in the present?</p>
    <p><strong>Marquard:</strong> Yes, you have to digest the shock first. Then you also have to get upset about the state for a few more days. But at some point, the issue is over. That my money is getting less and less, that I'm on ice, that's all clear. But we have food, we have drink. We're running out of money, but that affects all of us. And we certainly shouldn't be moping around right now. We can finally listen to ourselves and allow new ideas.</p>
    <p>So the Corona master plan is to reinvent ourselves now, think of new concepts now, and hold on to new ideas - and, if it's possible again, implement some of them.</p>
    <p><strong>Marquard:</strong> Not only that. You should really develop a plan for the future now, and also get the team involved. I don't have to wait until it's all over to do that. Because I don't know how long the whole thing will last. That's why it's even more important to look at what I can do now. Maybe there is a way I can make money with my skills.</p>
    <p>How do I get my employees to think about ideas for the future when insolvency is currently on the cards and jobs are at risk?</p>
    <p><strong>Marquard:</strong> That's not easy. I'm sure that at least a third of all small businesses in the restaurant industry have to make a cut and realign themselves. Many businesses have been operating for many years in such a way that they can just about make ends meet. And I recommend to such people that they simply write down whether they enjoy it and whether they want to continue in this way. I simply say: Better an end with horror than horror without an end - without wanting to offend anyone, we're all in the same boat. But this is the chance for us to reposition ourselves.</p>
    <p><strong>Marquard:</strong> That is the issue. I would like to see a swarm thinking emerge from this time. Together we are strong. There have to be people who can talk turkey and move the masses. Those of us who are in the public eye should connect and speak one language together.</p>
    <p>I am firmly convinced that in one to two years everything in the world will change completely. Because that's how long it's going to take for us to get a semblance of normalcy again. I suspect we'll have ups and downs until we find a vaccine - or until everyone is infected. But after that, there will be a different cohesion.</p>
    <p>Isn't there also a certain risk that everything could go completely wrong?</p>
    <p><strong>Marquard:</strong> Of course, something can go wrong. But that's also important. Only failure makes a person strong. Once someone has failed, they go through the roof afterwards. But no one is condemned to do nothing - and certainly not to starve. You just have to want to, then you can achieve something.</p>
    <p>Interview (in excerpts) in «Rolling Pin» 02.04.2020</p>
  `,
  authorOverview: 'He studied German literature, sports, philosophy and economics at the Philips University of Marburg and Ruprecht Karls University Heidelberg, where he got his doctorate with honors. Banker at Commerzbank and Dresdner Bank, Member of the Board of Sparkasse Kiel. Managing director, founder and investor of various companies in the areas of financial business, life style, food.',
}

export default <BlogPostType>({
  de: {...common, ...de},
  en: {...common, ...en},
})
